export class ChangePasswd {
  Id: number;
  Name: string; // username
  OldPassword: string;
  NewPswd: string;
  ConfirmPswd: string;
  FullName: string;
  RoleId: string;
  StaffId: string;
}
