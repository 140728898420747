import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from './utility.service';
import { ApiParams, Clinic } from '../models';
import { forkJoin, Observable, of } from 'rxjs';
import { SystemSettings } from '../models/system-settings';
import { filter, map, tap } from 'rxjs/operators';
import { SharedDataService } from './shared-data.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private baseUrl = '';
  allMenus: [];
  clinicInfo: Clinic;

  constructor(
    private util: UtilityService,
    private http: HttpClient,
    private sharedDataService: SharedDataService,
    @Inject('BASE_URL') baseURL: string
  ) {
    this.baseUrl = baseURL;
  }

  getClinicInfo() {
    const httpOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.getClinicInfo
    );
    return this.http.request('POST', this.baseUrl, httpOptions);

    // if (this.clinicInfo) {
    //     return of(this.clinicInfo);
    // } else {
    //     const httpOptions = this.util.getMappedHttpOptions({}, ApiParams.getClinicInfo);
    //      this.http.request('POST', this.baseUrl, httpOptions).pipe(
    //             map(x=>
    //                 this.clinicInfo = x as Clinic;
    //             ).sub
    //             )
    //         // .subscribe(result => {
    //         //     this.clinicInfo = result as Clinic;
    //         //     return of(this.clinicInfo);
    //         // });
    // }
  }

  setClinicInfo(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setClinicInfo
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getUserRoles() {
    const httpOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.getUserRoles
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  setUserRole(info) {
    const httpOptions = this.util.getMappedHttpOptions(
      info,
      ApiParams.setUserRoles
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getAllMenus() {
    // TODO : Replace the idcsv
    const httpOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.getAllMenus
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getOrderedMenuList(menuList) {
    const menus = menuList
      .filter((m) => m.ParentId == 0)
      .map((p) => {
        if (p.Url) {
          return { id: p.Id, title: p.Name, icon: p.Icon, link: p.Url };
        }
        const subMenus = menuList
          .filter((s) => s.ParentId == p.Id)
          .map((c) => {
            return {
              id: c.Id,
              title: c.Name,
              icon: '',
              link: c.Url,
            };
          });
        return { id: p.Id, title: p.Name, icon: p.Icon, children: subMenus };
      });

    return menus;
  }

  setUser(info: any) {
    const httpOptions = this.util.getMappedHttpOptions(info, ApiParams.setUser);
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getUsers() {
    const httpOptions = this.util.getMappedHttpOptions({}, ApiParams.getUsers);
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  changeUserPassword() {
    const httpOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.changeUserPassword
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getSystemSettings() {
    const httpOptions = this.util.getMappedHttpOptions(
      {},
      ApiParams.getSystemSettings
    );
    // return this.http.request('POST', this.baseUrl, httpOptions);

    if (!this.sharedDataService.systemSettings) {
      return this.http.request('POST', this.baseUrl, httpOptions).pipe(
        tap((data) => {
          console.log(data);
          this.sharedDataService.systemSettings = data as SystemSettings[];
        })
      );
    } else {
      return of(this.sharedDataService.systemSettings);
    }
  }

  setSystemSettings(data: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      data,
      ApiParams.setSystemSettings
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  setUserDetails(data: any) {
    const httpOptions = this.util.getMappedHttpOptions(
      data,
      ApiParams.setUserDetails
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }

  getUser(userId: number) {
    const httpOptions = this.util.getMappedHttpOptions(
      { Id: userId.toString() },
      ApiParams.getUserDetailsById
    );
    return this.http.request('POST', this.baseUrl, httpOptions);
  }
}
