import { AnalysisItem } from './analysis-ltem';
import { LabRequest } from './lab-request';

export class PatientVisit {
  constructor(init?: Partial<PatientVisit>) {
    this.SelectedAnalysisItemList = [];
    this.SubTotalAmount = 0;
    this.TotalAmount = 0;
    this.AdvanceAmount = 0;
    this.BalanceAmount = 0;
    Object.assign(this, init);
  }

  PatientVisitID: number;
  VisitedOn: Date;
  PatientID: string;
  PatGPID: string;
  PatientName: string;
  PatientAge: number;
  PatientMobile: string;
  PatientSex: string;
  DoctorID: number;
  ClinicalNotes: string;
  PaymentMethodId: number;
  SubTotalAmount: number;
  DiscountPercent: number;
  DiscountAmount: number;
  TotalAmount: number;
  AdvanceAmount: number; // PaidAmt
  BalanceAmount: number;
  Flag: string;
  StatusName: string;

  SelectedDoctor: any;
  PaymentMethod: any;

  SelectedAnalysisItemList: AnalysisItem[];
  LabRequestList: LabRequest[];
  IsFullAmountPaid: boolean;
  CollectedOn:Date;
  ResultEnteredOn:Date;
}
